import { ApplicationInsightsService } from "@shared/application-insights-service";
import { UserManager } from "oidc-client-ts";
import { h, render } from "preact";
import { CustomerLoginSettings } from "./customer-login-interfaces";
import { getCustomerLoginSettings } from "./customer-login-services";
import { CustomerLoginLoader } from "./ice-customer-login";

const addCustomerLogin = (container: Element) => {
  const applicationInsightsService = new ApplicationInsightsService();

  getCustomerLoginSettings(window.location.origin, window.location.href)
    .then((settings: CustomerLoginSettings) => {
      const userManager = new UserManager(settings.userManagerSettings);
      settings.userManager = userManager;
      settings.applicationInsightsService = applicationInsightsService;

      render(h(CustomerLoginLoader, settings), container);
    })
    .catch((err: Error) => {
      console.error("Failed to Load UserManagerSettings", err);
      applicationInsightsService.trackException(err, "Failed to load Oidc UserManagerSettings");
    });
};

const container = document.querySelector(".js-customer-login");
if (container) {
  addCustomerLogin(container);
}
